/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

// Services
import selectStorage from '../services/shared/selectStorage';
import { areCookiesEnabled } from '../services/shared/helpers';

// Other packages
import QRCode from 'react-qr-code';

// Components
import Header from './Header';

// Images
import loadingGif from '../assets/gifs/loading.gif';

// Config
import { imageAlt } from '../config/accessabilityRules';

class MobileHandoff extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.resendFocusRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            phone: null,
            isPhoneInputDirty: false,
            linkSent: false,
            color: '',
            error: null,
            uuid: '',
            lang: 'en',
        };
    }

    componentDidMount() {
        this.setState({
            uuid: selectStorage(areCookiesEnabled()).getItem('uuid'),
            lang: selectStorage(areCookiesEnabled()).getItem('lang'),
        });

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_handoff');

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }
    }

    buildHandoffUrl() {
        return (
            window.location.protocol +
            '//' +
            window.location.host +
            '?uuid=' +
            this.state.uuid +
            '&handoff_session=' +
            selectStorage(areCookiesEnabled()).getItem('sessionid') +
            '&lang=' +
            selectStorage(areCookiesEnabled()).getItem('lang') +
            '&target=webapp_mobile'
        );
    }

    render() {
        const { t } = this.props;

        // Awaiting pending
        if (this.props.pendingItems > 0) {
            return (
                <div className='u-text-center start-loading'>
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>
            );
        }

        if (this.state.linkSent === false) {
            return (
                <Fragment>
                    <Header />

                    <div className='o-site-wrap handoff'>
                        <div className=''>
                            <h1 ref={this.primaryFocusRef} tabIndex={0}>
                                {t('idpal_scan_qr_to_verify_your_identity')}
                            </h1>
                        </div>

                        <div className='container'>
                            <div className='handoff-option'>
                                <h3 className={'u-text-center'}>
                                    {t('idpal_scan_qr_code')}
                                </h3>
                                <QRCode
                                    className='qr'
                                    value={this.buildHandoffUrl()}
                                    size={200}
                                    title='Scan QR Code'
                                />
                                <p className='u-text-center qr-message'>
                                    {t('idpal_qr_message')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
    };
}

function mapDispatchToProps(dispatch) {}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(MobileHandoff)
);
