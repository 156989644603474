/**
 * @prettier
 */

// React packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from './../i18n';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';

// Actions
import {
    getCountries,
    setErrors,
    setFormValue,
    setPhone,
    setSelectedCountry,
    storeAddress,
    submitEVerification,
    setAutoAddressFocus,
    setDateErrorFocus,
} from './actions/eVerificationActions';

import { clearEVerificationAddress } from './actions/addressLookupActions';

// Services
import eVerificationValidatorService from '../services/shared/eVerificationValidatorService';
import {
    getCompanyName,
    isSubmissionComplete,
} from '../services/shared/helpers.js';

// Components
import Header from './Header';
import TextInput from './Inputs/TextInput';
import AddressLookup from './Inputs/AddressLookup';
import DateInput from './Inputs/DateInput';
import SelectInput from './Inputs/SelectInput';
import CustomButton from './Button';
import Label from './Inputs/Label';
import PhoneInput from 'react-phone-input-2';
import Navigator from './Navigator';
import StageComplete from './Messages/StageComplete.js';

// Images
import loadingGif from '../assets/gifs/loading.gif';

// Config
import {
    hiddenFormLabels,
    focusInputIds,
    imageAlt,
} from '../config/accessabilityRules';
import { eVerification } from '../config/eVerification.js';
import { ACTION_LABELS } from '../config/dataDogActionLabels';

const ACTION = ACTION_LABELS.eVerification;

class EVerification extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            inputValue: null,
            isAutoAddressOpen: false,
            isFirstRender: true,
            languageCode: i18n.language,
            loadingError: null,
            navigation: {
                action: 'load',
                props: {
                    eVerificationMode: this.props.eVerificationMode,
                },
            },
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.handleSelectInputBlur = this.handleSelectInputBlur.bind(this);
        this.handlePhoneBlur = this.handlePhoneBlur.bind(this);
        this.setPhoneInputAriaLabel = this.setPhoneInputAriaLabel.bind(this);
    }

    componentDidMount() {
        if (this.props.countries.length === 0) {
            this.props.getCountries();
        }
        // Reset input focus
        this.props.setAutoAddressFocus(false);
        this.props.setDateErrorFocus(false);
        if (this.props.selectedCountry.supports_address_lookup) {
            this.props.setAutoAddressFocus(true);
        }

        // Sets the document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_Everification');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.eVerificationCompleted !==
            this.props.eVerificationCompleted
        ) {
            this.setState({
                navigation: {
                    action: 'next',
                    props: {
                        eVerificationMode: this.props.eVerificationMode,
                    },
                },
            });
        }

        if (
            prevProps.selectedCountry.supports_address_lookup !==
            this.props.selectedCountry.supports_address_lookup
        ) {
            // Unsets focus if autoAddress not active on first render
            if (!this.props.setSelectedCountry.supports_address_lookup) {
                this.setState({ isFirstRender: false });
            }
            // Clears address inputs if auto address is selected
            if (this.props.selectedCountry.supports_address_lookup) {
                this.props.clearEVerificationAddress();
                this.props.setAutoAddressFocus(true);
                // Sets focus on first name if autoAddress input if active on the first render
                if (this.state.isFirstRender) {
                    this.setState({ isAutoAddressOpen: true });
                    this.setState({ isFirstRender: false });
                    this.props.setAutoAddressFocus(false);
                }
            }
        }
    }

    validate(formData) {
        // Set validation results
        const errors = eVerificationValidatorService(
            formData,
            this.props.customFields
        );
        this.props.setErrors(errors);
        this.setFocus(errors);

        // No errors, submit form
        return Object.keys(errors).length === 0;
    }

    // Define elements that should recieve focus on error
    setFocus(errors) {
        if (!errors) {
            return;
        }

        let focusError = null;

        // Get the first input in error
        Object.entries(errors).every(([key, value]) => {
            focusError = this.formRef.current.querySelector(`#${key}`);
            return focusError === null;
        });

        // Set focus to auto address lookup input on error
        if (!focusError && this.state.isAutoAddressOpen) {
            const autoAddressInput = this.formRef.current.querySelector(
                `#${focusInputIds.addressLookup}`
            );
            focusError = autoAddressInput;
        }

        if (focusError) {
            // Dob has multiple inputs so it must be excluded and set internal to the SimpleDateInput component
            if (focusError.id === eVerification.dob.id) {
                this.props.setDateErrorFocus(true);
            } else {
                focusError.focus();
            }
        }
    }

    handleSubmit(event) {
        let formData = {
            firstName: this.props.firstName ? this.props.firstName : '',
            lastName: this.props.lastName ? this.props.lastName : '',
            dob: this.props.dob ? this.props.dob : '',
            address1: this.props.address1 ? this.props.address1 : '',
            address2: this.props.address2 ? this.props.address2 : '',
            city: this.props.city ? this.props.city : '',
            postCode: this.props.postCode ? this.props.postCode : '',
            country: this.props.selectedCountry.name
                ? this.props.selectedCountry.name
                : '',
            customField: this.props.customField ? this.props.customField : '',
            phone: this.props.phone ? this.props.phone : '',
            phonePrefix: this.props.phonePrefix ? this.props.phonePrefix : '',
            phoneRaw: this.props.phoneRaw ? this.props.phoneRaw : '',
        };

        // Prevent automatic submitting of form if triggered by submit button
        if (event.preventDefault) {
            event.preventDefault();
        }

        if (this.validate(formData)) {
            // If the selected country is an activated country, submit to be verified
            if (
                this.props.activatedCountries.includes(
                    this.props.selectedCountry.name
                )
            ) {
                this.props.submitEVerification(formData, this);

                // If it is not an activated country, then submit to be stored.
            } else {
                this.props.storeAddress(formData, this);
            }
        }
    }

    // Set hidden error label for screen readers
    setPhoneInputAriaLabel() {
        let ariaLabel = `${hiddenFormLabels.mobile} ${hiddenFormLabels.infoTip}`;
        if (this.props.errors.phone) {
            ariaLabel = `${hiddenFormLabels.mobile} ${this.props.errors.phone}`;
        }
        return ariaLabel;
    }

    selectCountry(event) {
        this.props.setSelectedCountry(event.target.value, this.props.countries);
    }

    // Renders address lookup on input blur to maintain form focus
    handleSelectInputBlur() {
        if (this.props.selectedCountry.supports_address_lookup) {
            this.setState({ isAutoAddressOpen: true });
        } else {
            this.setState({ isAutoAddressOpen: false });
            // Sets next focus to autoAddress input
            this.props.setAutoAddressFocus(true);
        }
    }

    // Removes auto focus on input blur to maintain form focus
    handlePhoneBlur() {
        this.props.setAutoAddressFocus(false);
    }

    showAddressLookup() {
        if (!this.props.addressLookupActive) {
            return false;
        }
        if (this.props.addressLookupHidden) {
            return false;
        }
        if (this.state.isAutoAddressOpen) {
            return true;
        }
    }

    render() {
        const { t } = this.props;
        // Get date format
        const dateformat =
            this.props.selectedCountry.iso_3166_2 === 'US' ? 'mdy' : 'dmy';

        if (isSubmissionComplete()) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        message={t('idpal_your_submission_is_complete', {
                            company: getCompanyName(this.props.companyName),
                        })}
                        hideContinue={true}
                        hideButton={true}
                    />
                </Fragment>
            );
        }

        // Awaiting pending
        if (this.props.pendingItems > 0) {
            return (
                <div className='u-text-center start-loading'>
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>
            );
        }

        return (
            <Fragment>
                <Header />

                <div className='form-wrap'>
                    <Fragment>
                        <h1 className={'u-text-center'}>
                            {t('idpal_please_add_personal_details')}
                        </h1>
                        <form onSubmit={this.handleSubmit} ref={this.formRef}>
                            <div className='form-wrap__inner'>
                                <div className='row'>
                                    <TextInput
                                        config={eVerification.firstName}
                                        error={this.props.errors.firstName}
                                        onChange={this.props.setFormValue}
                                        actionDataLabel={ACTION.lastNameInput}
                                    />
                                    <TextInput
                                        config={eVerification.lastName}
                                        error={this.props.errors.lastName}
                                        onChange={this.props.setFormValue}
                                        actionDataLabel={ACTION.lastNameInput}
                                    />
                                </div>

                                <div className='row'>
                                    <DateInput
                                        config={eVerification.dob}
                                        error={this.props.errors.dob}
                                        format={dateformat}
                                        onChange={this.props.setFormValue}
                                        actionDataLabels={{
                                            month: ACTION.dobMonthInput,
                                            day: ACTION.dobDayInput,
                                            year: ACTION.dobYearInput,
                                        }}
                                    />
                                    <div
                                        className={
                                            this.props.errors.phone
                                                ? 'error input-group'
                                                : 'input-group'
                                        }
                                        data-dd-action-name={ACTION.phoneInput}
                                    >
                                        <Label
                                            id={'phone'}
                                            required={true}
                                            label={t('idpal_phone')}
                                            infoTip={t('idpal_phone_info')}
                                        />
                                        <PhoneInput
                                            enableSearch
                                            disableSearchIcon
                                            enableLongNumbers
                                            country={
                                                this.props.initialCountry
                                                    ? this.props.initialCountry.toLowerCase()
                                                    : ''
                                            }
                                            inputClass={'phone-input'}
                                            placeholder={''}
                                            value={this.props.phone}
                                            onChange={this.props.setPhone}
                                            onBlur={this.handlePhoneBlur}
                                            inputProps={{
                                                id: 'phone',
                                                'aria-label':
                                                    this.setPhoneInputAriaLabel(),
                                                'aria-invalid': this.props
                                                    .errors.phone
                                                    ? true
                                                    : false,
                                            }}
                                        />
                                        {this.props.errors.phone && (
                                            <span className='error-message'>
                                                {this.props.errors.phone}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {!this.showAddressLookup() && (
                                    <Fragment>
                                        <div className='row'>
                                            <SelectInput
                                                onChange={this.selectCountry}
                                                onBlur={
                                                    this.handleSelectInputBlur
                                                }
                                                options={this.props.countries}
                                                error={
                                                    this.props.errors.country
                                                }
                                                config={eVerification.country}
                                                default={
                                                    this.props.selectedCountry
                                                        .iso_3166_3
                                                }
                                                actionDataLabel={
                                                    ACTION.countryInput
                                                }
                                            />
                                            <TextInput
                                                config={eVerification.address1}
                                                error={
                                                    this.props.errors.address1
                                                }
                                                value={this.props.address1}
                                                onChange={
                                                    this.props.setFormValue
                                                }
                                                actionDataLabel={
                                                    ACTION.addressOneInput
                                                }
                                            />
                                        </div>

                                        <div className='row'>
                                            <TextInput
                                                config={eVerification.address2}
                                                error={
                                                    this.props.errors.address2
                                                }
                                                value={this.props.address2}
                                                onChange={
                                                    this.props.setFormValue
                                                }
                                                actionDataLabel={
                                                    ACTION.addressTwoInput
                                                }
                                            />

                                            <TextInput
                                                config={eVerification.city}
                                                error={this.props.errors.city}
                                                value={this.props.city}
                                                onChange={
                                                    this.props.setFormValue
                                                }
                                                actionDataLabel={
                                                    ACTION.cityInput
                                                }
                                            />
                                        </div>

                                        <div className='row'>
                                            <TextInput
                                                config={eVerification.postCode}
                                                error={
                                                    this.props.errors.postCode
                                                }
                                                value={this.props.postCode}
                                                onChange={
                                                    this.props.setFormValue
                                                }
                                                actionDataLabel={
                                                    ACTION.postCodeInput
                                                }
                                            />

                                            {/*Optional custom field based on selected country*/}
                                            {this.props.customFields.map(
                                                field => (
                                                    <Fragment key={field.iso}>
                                                        {this.props
                                                            .selectedCountry
                                                            .iso_3166_3 ===
                                                            field.iso && (
                                                            <TextInput
                                                                config={field}
                                                                error={
                                                                    this.props
                                                                        .errors
                                                                        .customField
                                                                }
                                                                onChange={
                                                                    this.props
                                                                        .setFormValue
                                                                }
                                                                actionDataLabel={
                                                                    ACTION.customField
                                                                }
                                                            />
                                                        )}
                                                    </Fragment>
                                                )
                                            )}
                                        </div>
                                    </Fragment>
                                )}

                                {this.showAddressLookup() && (
                                    <Fragment>
                                        <div className='row'>
                                            <SelectInput
                                                onChange={this.selectCountry}
                                                onBlur={
                                                    this.handleSelectInputBlur
                                                }
                                                options={this.props.countries}
                                                error={
                                                    this.props.errors.country
                                                }
                                                config={eVerification.country}
                                                default={
                                                    this.props.selectedCountry
                                                        .iso_3166_3
                                                }
                                                actionDataLabel={
                                                    ACTION.countryInput
                                                }
                                            />
                                            {this.showAddressLookup() && (
                                                <AddressLookup
                                                    error={
                                                        this.props.errors
                                                            .address1
                                                    }
                                                />
                                            )}
                                        </div>

                                        <div className='row'>
                                            {/*Optional custom field based on selected country*/}
                                            {this.props.customFields.map(
                                                field => (
                                                    <Fragment key={field.iso}>
                                                        {this.props
                                                            .selectedCountry
                                                            .iso_3166_3 ===
                                                            field.iso && (
                                                            <TextInput
                                                                config={field}
                                                                error={
                                                                    this.props
                                                                        .errors
                                                                        .customField
                                                                }
                                                                onChange={
                                                                    this.props
                                                                        .setFormValue
                                                                }
                                                                actionDataLabel={
                                                                    ACTION.customField
                                                                }
                                                            />
                                                        )}
                                                    </Fragment>
                                                )
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </div>

                            <CustomButton
                                className='btn'
                                label={t('idpal_continue')}
                            />
                        </form>
                    </Fragment>
                </div>

                <Navigator
                    page={'e_verification'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        branding: state.config.profile,
        pendingItems: state.spinner.pendingItems,
        customFields: state.eVerification.customFields,
        countries: state.eVerification.countries,
        selectedCountry: state.eVerification.selectedCountry,
        initialCountry: state.eVerification.initialCountry,
        activatedCountries: state.eVerification.activatedCountries,
        addressLookupHidden: state.addressLookup.hidden,
        addressLookupActive: state.addressLookup.active,
        isErrorFocus: state.eVerification.isErrorFocus,
        firstName: state.eVerification.firstName,
        lastName: state.eVerification.lastName,
        dob: state.eVerification.dob,
        address1: state.eVerification.address1,
        address2: state.eVerification.address2,
        city: state.eVerification.city,
        postCode: state.eVerification.postCode,
        customField: state.eVerification.customField,
        errors: state.eVerification.errors,
        phone: state.eVerification.phone,
        phonePrefix: state.eVerification.phonePrefix,
        phoneRaw: state.eVerification.phoneRaw,
        eVerificationMode: state.eVerification.mode,
        acuantInitialized: state.config.acuantInitialized,
        screens: state.submissionState.screens,
        eVerificationCompleted:
            state.submissionState.submissionState.e_verification.completed,
        companyName: state.config.profile.data.company_branding.name,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSelectedCountry,
            getCountries,
            setFormValue,
            setPhone,
            submitEVerification,
            storeAddress,
            setErrors,
            setAutoAddressFocus,
            setDateErrorFocus,
            clearEVerificationAddress,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(EVerification)
);
